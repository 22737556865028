import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useAlleTreatments } from '@packages/core-split-feature-flag/src/useAlleTreatments';
import { caFeatureKeys } from '@packages/core-split-feature-flag/src/consumer';

import { useAuth } from '../../auth/AuthProvider';
import { AuthState } from '../../auth/authMachine';
import { useCmsPreview } from '../../hooks/useCmsPreview';
import { LoggedInHeader } from './loggedInHeader';
import { LoggedOutHeader } from './loggedOutHeader';
import { Header } from '../../layout/header/headerBase';
import { LoggedOutNavigator } from './loggedOutNavigator';

interface HeaderDynamicProps extends RouteComponentProps {
  loggedOutVariant?: 'light' | 'transparent' | 'transparentBlackText';
  keepPathOnLogin?: boolean;
  jumpLink?: string;
  compact?: boolean;
  relative?: boolean;
  layoutVariant?: 'base' | 'navigator';
}

const HeaderDynamic: React.FunctionComponent<HeaderDynamicProps> = ({
  loggedOutVariant,
  keepPathOnLogin,
  jumpLink,
  compact,
  relative,
  layoutVariant = 'base',
  ...forwardProps
}) => {
  const { authState } = useAuth();
  const isHeaderRedesignEnabled =
    useAlleTreatments({
      splitNames: [caFeatureKeys.headerRedesign],
    })[caFeatureKeys.headerRedesign]?.treatment === 'on';

  const { cmsLoggedInPreview, cmsLoggedOutPreview } = useCmsPreview();

  if (isHeaderRedesignEnabled) {
    return (
      <Header
        keepPathOnLogin={keepPathOnLogin}
        jumpLink={jumpLink}
        relative={relative}
      />
    );
  }

  if (cmsLoggedInPreview ?? authState.matches(AuthState.isLoggedIn)) {
    return <LoggedInHeader compact={compact} {...forwardProps} />;
  }

  if (cmsLoggedOutPreview ?? authState.matches(AuthState.isNotLoggedIn)) {
    return layoutVariant === 'navigator' ? (
      <LoggedOutNavigator
        variant={loggedOutVariant || 'light'}
        keepPathOnLogin={keepPathOnLogin}
        jumpLink={jumpLink}
        {...forwardProps}
      />
    ) : (
      <LoggedOutHeader
        variant={loggedOutVariant || 'light'}
        keepPathOnLogin={keepPathOnLogin}
        jumpLink={jumpLink}
        compact={compact}
        {...forwardProps}
      />
    );
  }

  return null;
};

export { HeaderDynamic };
