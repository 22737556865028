import React, { useEffect, useRef } from 'react';
import { Button } from '@allergan-data-labs/alle-elements-button';
import { ChevronDownIcon } from '@allergan-data-labs/alle-elements-icons/20/chevronDownIcon';
import { ChevronUpIcon } from '@allergan-data-labs/alle-elements-icons/20/chevronUpIcon';
import { useColorMode } from '@allergan-data-labs/alle-elements-core';
import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { NavigationItemLinks } from './headerDrawerItem';
import { useHeaderColors } from './useHeaderColors';
import { useHeaderTrackingEvents } from './useHeaderTrackingEvents';

const getMenuButtonStyles = ({
  navLinkHoverColor,
}: {
  navLinkHoverColor: string;
}) => ({
  _hover: {
    '&:not(.static)': {
      backgroundColor: 'transparent',
      color: `${navLinkHoverColor}`,
    },
  },
  _active: {
    '&:not(.static)': {
      backgroundColor: 'transparent',
      _hover: {
        backgroundColor: 'transparent',
      },
    },
  },
});

const getMenuListStyles = ({ colorMode }: { colorMode: string }) => ({
  maxHeight: 'unset',
  maxWidth: '266px',
  boxSizing: 'border-box',
  overflowX: 'hidden',
  overflowY: 'auto',
  bg: `${colorMode}.Container/Neutral/Base`,
  color: `${colorMode}.Text/Neutral/Default`,
  padding: 8,
  border: 'none',
  boxShadow: 'Elevation/2',
  borderRadius: '8',
  '.chakra-menu__group:not(:first-of-type)': {
    borderTop: '1px',
    borderColor: `${colorMode}.Border/Neutral/Subtle 2`,
    paddingTop: '8',
    marginTop: '8',
  },
  '.action-menu__header + .chakra-menu__group': {
    borderTop: '0',
  },
});

const getMenuItemStyles = ({ colorMode }: { colorMode: string }) => ({
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  borderRadius: 'Small',
  background: `${colorMode}.Container/Neutral/Transparent interactive/Enable`,
  _hover: {
    background: `${colorMode}.Container/Neutral/Transparent interactive/Hover`,
  },
  _active: {
    background: `${colorMode}.Container/Neutral/Transparent interactive/Pressed`,
  },
  _focusVisible: {
    background: `${colorMode}.Container/Neutral/Transparent interactive/Hover`,
  },
});

type HeaderSelectProps = NavigationItemLinks & {
  isLoggedIn?: boolean;
  hideOnScroll?: boolean;
};

const HeaderSelect = ({
  title,
  items,
  hideOnLoggedInNav,
  isLoggedIn,
  hideOnScroll,
}: HeaderSelectProps) => {
  const { colorMode } = useColorMode();
  const buttonSelectRef = useRef<HTMLDivElement>(null);
  const btn = buttonSelectRef.current;

  const { Link } = useEnvironmentContext();
  const { navLinkColor, navLinkHoverColor } = useHeaderColors({
    isLoggedIn,
  });
  const { trackHeaderLinkClick } = useHeaderTrackingEvents();

  useEffect(() => {
    // Programmatically close dropdown when scroll trigger is true (same trigger that hides the entire nav bar)
    if (hideOnScroll && btn?.getAttribute('aria-expanded') === 'true') {
      btn?.click();
    }
  }, [btn, hideOnScroll]);

  if (isLoggedIn && hideOnLoggedInNav) {
    return null;
  }

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            ref={buttonSelectRef}
            rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            iconSpacing={2}
            variant="ghost"
            padding={0}
            height="32px"
            color={navLinkColor}
            backgroundColor="transparent"
            fontSize="4"
            sx={{ ...getMenuButtonStyles({ navLinkHoverColor }) }}
            onClick={() =>
              trackHeaderLinkClick({
                title: title || '',
              })
            }
          >
            {title}
          </MenuButton>
          <MenuList sx={{ ...getMenuListStyles({ colorMode }) }}>
            {items?.map((item) => {
              return (
                <MenuItem
                  key={item.id}
                  sx={{ ...getMenuItemStyles({ colorMode }) }}
                >
                  <Link
                    href={item.href}
                    data-testid={`header-nav-${item.id}`}
                    paddingY={8}
                    paddingX={12}
                    width="100%"
                    fontSize="14px"
                    fontWeight={item.isActive ? '600' : '400'}
                    isStandalone
                    variant="action"
                    _hover={{
                      textDecoration: 'none',
                    }}
                    onClick={() =>
                      trackHeaderLinkClick({
                        brand: item.title || '',
                        url: item.href,
                      })
                    }
                  >
                    {item.title}
                  </Link>
                </MenuItem>
              );
            }) || []}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export { HeaderSelect };
