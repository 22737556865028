import React from 'react';
import { ApolloError } from 'apollo-client';

import { Box } from '@allergan-data-labs/alle-elements-layout';
import { getColorToken } from '@allergan-data-labs/alle-elements-core';

import { ChevronRightIcon } from '@allergan-data-labs/alle-elements-icons/20/chevronRightIcon';
import {
  Consumer_Tier,
  ConsumerProfile,
} from '@packages/core-alle-graphql-types';
import { pointsToPercentage } from '@packages/deprecated-universal-component-library/src/helpers/pointsToPercentage';
import { CircularProgressBar } from '@packages/deprecated-consumer-component-library/src/circularProgress/circularProgressBar';
import { useSegment } from '@packages/core-segment-tracking/src/segmentContext';
import { logger } from '@packages/core-datadog-logging/src/logger';

import { ErrorIcon } from '@packages/deprecated-component-library/src/icons/errorIcon';
import { ElementEngaged } from '@packages/core-segment-tracking/src/generated';

import { useAuth } from '../../../auth/AuthProvider';
import { AlistSkeletonLoader } from './tier/alistSkeletonLoader';
import { TierAListTypes } from './tier/alistTier/alistTypes';
import { AlistTierContent } from './tier/alistTier/alistTierContent';
import { AlistTierSegmentMetadata } from './tier/alistTier/alistSegmentMetadata';

const AListTier = ({
  profile,
  loading,
  type,
  error,
  onOpenAListDialog,
}: {
  profile: Partial<ConsumerProfile>;
  type: TierAListTypes;
  loading?: boolean;
  error?: ApolloError | undefined;
  onOpenAListDialog?: () => void;
}) => {
  const { title, message, path } = AlistTierContent(
    type,
    profile?.loyalty?.points?.tierThreshold
  );
  const { authState } = useAuth();
  const { trackConsumer } = useSegment();

  const handleClickOpen = () => {
    const isLoggedIn = authState.matches('isLoggedIn')
      ? 'logged_in'
      : 'logged_out';
    const AlistSegmentData = AlistTierSegmentMetadata(
      type,
      isLoggedIn,
      profile?.loyalty?.points?.id,
      'website',
      'Profile'
    ) as ElementEngaged;
    trackConsumer()?.elementEngaged(AlistSegmentData);
    onOpenAListDialog?.();
  };

  if (!profile.loyalty?.points || error) {
    logger.error('AlistTierStatus--Error', {
      error: error,
    });

    if (!profile.loyalty?.points) {
      logger.warn('LoyaltyPointsNoData--warn');
    }

    return (
      <Box marginTop={'16px'} paddingTop={'16px'}>
        <AlistSkeletonLoader backgroundColor={`#FFFFFF1F`} />
        <Box data-testid="error-wrapper" display={'flex'} gap={'8px'}>
          <ErrorIcon color={'#C53425'} />
          <Box textStyle={'Body/Small/Regular'} color={'#C53425'}>
            We encountered an error loading some of your information. Please try
            again.
          </Box>
        </Box>
      </Box>
    );
  }

  const {
    loyalty: {
      tier,
      points: { tierPoints, tierThreshold },
    },
  } = profile;

  const isAList = tier === Consumer_Tier.AList;

  const computedTierPoints = pointsToPercentage(tierPoints, tierThreshold);

  return loading ? (
    <AlistSkeletonLoader backgroundColor={`#FFFFFF1F`} />
  ) : (
    <Box
      data-testid={'alist-tier'}
      borderTop={`1px solid ${getColorToken(
        'Border/Neutral/Default 08',
        isAList ? 'dark' : 'light'
      )}`}
      padding={'16px'}
      display={'grid'}
      gridTemplateColumns={'auto 80px'}
      onClick={handleClickOpen}
      _hover={{ cursor: 'pointer' }}
    >
      <Box marginRight={'8px'}>
        <Box
          data-testid="alist-tier-title"
          textStyle="Body/Medium/Regular"
          color={
            isAList
              ? `dark.Text/Neutral/Interactive/Enable`
              : `light.Text/Neutral/Interactive/Enable`
          }
          marginBottom={'4px'}
        >
          {title}
        </Box>
        <Box
          data-testid="alist-tier-message"
          textStyle={`Footnote/Regular`}
          color={
            isAList ? `dark.Text/Neutral/Support` : `light.Text/Neutral/Support`
          }
        >
          {message}
        </Box>
      </Box>
      <Box
        data-testid="progress-wrapper"
        display={'grid'}
        gridTemplateColumns={'4fr 1fr'}
        gridGap={'8px'}
      >
        <CircularProgressBar
          containerStyles={{ height: '52px', width: '52px', marginTop: '4px' }}
          _buildStyles={{
            rotation: 0.6,
            pathColor: path,
            trailColor: `#C0867633`,
            strokeLinecap: 'butt',
          }}
          value={computedTierPoints}
          circleRatio={0.8}
          strokeWidth={4}
        >
          <Box
            data-testid="alist-tier-computed-points"
            textStyle={'Body/Small/Regular'}
            textColor={'#C3A45E'}
            marginTop={'-12px'}
          >
            {computedTierPoints}%
          </Box>
        </CircularProgressBar>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <ChevronRightIcon
            color={
              isAList
                ? 'dark.Icon/Neutral/Interactive/Enable'
                : 'light.Icon/Neutral/Interactive/Enable'
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export { AListTier };
