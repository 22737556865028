import React, { ReactNode } from 'react';
import { EnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';
import { ReactRouterLink } from '@packages/core-components/src/components/environment-context/link/reactRouterLink';
import { useReactRouterNavigation } from './useReactRouterNavigation';

const EnvironmentContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useReactRouterNavigation();

  return (
    <EnvironmentContext.Provider value={{ Link: ReactRouterLink, navigate }}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export { EnvironmentContextProvider };
