import { Maybe } from '@packages/core-alle-graphql-types';

import { TierAListTypes, TrackerContentProps } from './alistTypes';
import { currentYear } from './alistHelpers';
import { formatNumberWithCommas } from '../../../../../helpers/format';

export const AlistTrackerContent = (
  type: TierAListTypes,
  points: Maybe<number> | undefined,
  tierThreshold: number
): TrackerContentProps => {
  const listStatus = {
    MEMBER: {
      title: `You’ve earned ${points} points this year.`,
      message: `Reach A-List by earning ${formatNumberWithCommas(
        tierThreshold - points!
      )} more points between now and December 31, ${currentYear}.`,
      progressBackground: '#DEDAD7',
      progressColor: '#9A6B5E',
      progressTextColor: '#9A6B5E',
      titleColor: '#090909',
      iconColor: '#787676',
      messageColor: '#090909',
      badgeColor: '#D09284',
      theme: `linear-gradient(
         0deg,
         #FFFFFFE6,
         #FFFFFFE6
       ),
       #C08676;`,
      perksTheme: '#FFFFFF',
    },
    NEW_A_LISTER: {
      title: 'You’ve reached A-List!',
      message: `You have access to all the best A-List perks through ${
        currentYear + 1
      }.`,
      progressBackground: `#FFFFFF33`,
      progressColor: 'linear-gradient(to right, #8B6A2B, #F8D98B, #836428)',
      progressTextColor: '#C3A45E',
      titleColor: '#FFFFFF',
      iconColor: '#FFFFFF',
      messageColor: '#B3B0AE',
      badgeColor: '#B3B0AE',
      theme: '#090909',
      perksTheme: '#1C1B1B',
    },
    A_LISTER: {
      title: `You’ve earned ${points} points this year.`,
      message: `Keep your A-List status by earning ${formatNumberWithCommas(
        tierThreshold - points!
      )} more points between now and December 31, ${currentYear}.`,
      progressBackground: `#FFFFFF33`,
      progressColor: 'linear-gradient(to right, #8B6A2B, #F8D98B, #836428)',
      progressTextColor: '#C3A45E',
      titleColor: '#FFFFFF',
      iconColor: '#FFFFFF',
      messageColor: '#B3B0AE',
      badgeColor: '#B3B0AE',
      theme: '#090909',
      perksTheme: '#1C1B1B',
    },
    RETURNING_A_LISTER: {
      title: 'Another year on the A-List!',
      message: `Enjoy continued access to all the best A-List perks through ${
        currentYear + 1
      }.`,
      progressBackground: `#FFFFFF33`,
      progressColor: 'linear-gradient(to right, #8B6A2B, #F8D98B, #836428)',
      progressTextColor: '#C3A45E',
      titleColor: '#FFFFFF',
      iconColor: '#FFFFFF',
      messageColor: '#B3B0AE',
      badgeColor: '#B3B0AE',
      theme: '#090909',
      perksTheme: '#1C1B1B',
    },
  };
  return listStatus[type] ?? listStatus.MEMBER;
};
