const onOffTreatment = {
  on: 'on',
  off: 'off',
};

const crFeatureKeys = {
  profileRedesign: 'consumer_profile_redesign_web',
  homepageCarousel: 'consumer_homepageCarousel_web',
  wallet: {
    consumerOfferHistory: 'consumer_offer_history_web',
  },
  landingPage: {
    juvDayStandBy: 'consumer_juvDayStandBy_frontend',
    botoxDayStandby: 'consumer_botoxDayStandby_frontend',
    consumerBotox23DayContentfulDownRedirect:
      'consumer_botox23_day_contentful_down_redirect',
  },
} as const;

const financingFeatureKeys = {
  showAlternateApprovalScreen: 'financing_alternateApprovalScreen_web',
  useFullWidthOnFinancingApplicationIframe:
    'financing_useFullWidthForApplication_web',
  showEstimatorInWallet: 'financing_showEstimatorInWallet_web',
} as const;

const growFeatureKeys = {
  landingPage: {
    layout: 'growLandingPage_layout_web',
  },
  offerActivation: {
    errorMessage: 'growOfferActivation_errorMessage_web',
  },
  practiceCampaignCard: {
    hideChatNow: 'grow_practiceCampaignCard_hideChatNow',
  },
  optInOffers: {
    consumerBotox50OfferGrowTestForceExpired:
      'consumer_botox_50_offer_growtest_force_expired_web',
    consumerBotox50OfferGrowTestForceNotStarted:
      'consumer_botox_50_offer_growtest_force_not_started_web',
    consumerBotoxCMPOfferGrowTestForceExpired:
      'consumer_botox_cmp_offer_force_expired_web',
    consumerBotoxCMPOfferGrowTestForceNotStarted:
      'consumer_botox_cmp_offer_force_not_started_web',
  },
} as const;

const optimizeRegFeatureKeys = {
  autofocusRegFields: 'consumer_registrationFieldsAutofocus_web',
  optInOfferInlineClaim: 'consumer_optInOfferInlineClaim_web',
} as const;

const optimizeRegTreatments = {
  [optimizeRegFeatureKeys.autofocusRegFields]: onOffTreatment,
  [optimizeRegFeatureKeys.optInOfferInlineClaim]: onOffTreatment,
} as const;

const pdFeatureKeys = {
  providerDirectoryShowFreeConsultation:
    'providerDirectory_showFreeConsultation_web',
  providerDirectoryBrowserLocationPrompt:
    'providerDirectory_browserLocationPrompt_web',
  providerDirectoryBrowserLocationPromptLegacy:
    'providerDirectory_browserLocationPromptLegacy_web',
  providerDirectoryGoogleGeocoding: 'providerDirectory_googleGeocoding_web',
  providerDirectoryGoogleReviews: 'providerDirectory_googleReviews_web',
  providerDirectoryReactQuery: 'providerDirectory_reactQuery_web',
  providerDirectoryConsultationOfferVariant:
    'providerDirectory_consultationOfferVariants_web',
  providerDirectoryFindAProviderButton:
    'providerDirectory_findAProviderButton_web',
  providerDirectoryProfilePageRedesign:
    'providerDirectory_profilePageRedesign_web',
  providerDirectorySearchSheetRedesign:
    'providerDirectory_searchSheetRedesign_web',
  providerDirectorySearchProviderName:
    'providerDirectory_searchProviderName_web',
  providerDirectorySearchResultsPerPage:
    'providerDirectory_searchResultsPerPage_web',
  providerDirectorySearchRadius: 'providerDirectory_searchRadius_web',
} as const;

// consumer acquisition squad
const caFeatureKeys = {
  imageOptimizations: 'consumer_imageOptimizations_web',
  pocMultiApp: 'providerDirectory_pocMultiApp_web',
  providerAboutUs: 'consumer_providerProfileAboutUs_web',
  providerLanguages: 'consumer_providerProfileLanguages_web',
  headerRedesign: 'consumer_headerRedesign_web',
  permanent: {
    hiddenProducts: 'consumer_hiddenProductsPermanent_web',
    newProducts: 'consumer_newProductsPermanent_web',
    hiddenTreatmentAreas: 'consumer_hiddenTreatmentAreasPermanent_web',
    newTreatmentAreas: 'consumer_newTreatmentAreasPermanent_web',
  },
  treatmentGuideRedesign: 'treatmentGuide_redesign_web',
  homepageRedesign: 'treatmentGuide_redesign_web', // used to be consumer_homepageRedesign_web but want to be able to do slow rollout w/ the TG ff
  dashboardRedesign: 'consumer_dashboardRedesign_web',
  profileProductCardCmsBrands: 'consumer_profileProductCardCmsBrands_web',
  authSyncSegmentFix: 'consumer_authSyncSegmentFix_web',
  rewardsRollbackUpdates: 'consumer_rewardsRollbackUpdates_web',
};

const caTreatments = {
  [caFeatureKeys.imageOptimizations]: onOffTreatment,
};

const rafFeatureKeys = {
  referralWideScale: 'refer_a_friend_wide_scale',
} as const;

const passTheLeadFeatureKeys = {
  passTheLeadInlineOfferClaim: 'ges1_passTheLeadExperiment_frontend',
  passTheLeadSettings: 'ges1_passTheLeadSettings_frontend',
  passTheLeadPatientTreatmentHistory:
    'consumer_passTheLeadTreatmentHistory_web',
};

const passTheLeadRegTreatments = {
  [passTheLeadFeatureKeys.passTheLeadInlineOfferClaim]: onOffTreatment,
  [passTheLeadFeatureKeys.passTheLeadSettings]: onOffTreatment,
  [passTheLeadFeatureKeys.passTheLeadPatientTreatmentHistory]: onOffTreatment,
};

export {
  crFeatureKeys,
  growFeatureKeys,
  optimizeRegFeatureKeys,
  optimizeRegTreatments,
  pdFeatureKeys,
  rafFeatureKeys,
  caFeatureKeys,
  caTreatments,
  passTheLeadFeatureKeys,
  passTheLeadRegTreatments,
  financingFeatureKeys,
};
